.accordion-button {
  background-color: lightblue !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: black;
}
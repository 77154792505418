.M-ProfileAccess {
  display: flex;
  z-index: 1000;
}

.M-ProfileAccess__toggleLink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.bosch-website .M-ProfileAccess__userNameButton {
  font-size: 0.875rem;
}

.M-ProfileAccess__dropDownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.M-ProfileAccess__dropDownHeadline {
  font-size: 1rem;
  font-weight: 200;
}

.M-ProfileAccess__loginBtn .A-Link__link:active,
.M-ProfileAccess__loginBtn .A-Link__link:active:hover {
  color: #fff;
}

.M-ProfileAccess__linksContainer,
.M-ProfileAccess__userInfoContainer {
  border-bottom: 1px solid #d9d9d9;
}

.M-ProfileAccess__loggedInInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.M-ProfileAccess__userInfoContainer {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1em;
}

.M-ProfileAccess__userInfoContainer .A-Link--button {
  font-size: 0.75rem;
}

.M-ProfileAccess__linksContainer {
  padding-top: 20px;
  padding-bottom: 10px;
}

.M-ProfileAccess__linksContainer .A-Link {
  font-size: 0.75rem;
  font-weight: 200;
  margin-bottom: 10px;
}

.M-ProfileAccess__userMonogram {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
  margin-right: 15px;
  background-color: #008ecf;
  background-image: linear-gradient(to right, #008ecf 10%, #00a8b0 90%);
  background-size: cover;
  background-position: center center;
}

.M-ProfileAccess__userEmail {
  font-weight: 700;
}

.M-ProfileAccess__appHeadline {
  margin-bottom: 10px;
  font-size: 0.75rem;
}

.M-ProfileAccess__logoutBtnContainer .A-Button {
  white-space: nowrap;
}

@media (min-width: 992px) {
  .M-ProfileAccess__logoutBtnContainer {
    font-size: 0.875rem;
  }
}

#home-page{
	display: flex;
    flex-direction: column;
	margin: 1em;
}

.custom-tabs .nav-link {
  color: #6c757d;
}

.custom-tabs .nav-link.active {
  color: purple !important; 
  border-bottom: 3px solid purple !important;
  font-weight: bold;
  padding-bottom: 0;
}


.custom-tabs .nav-link:hover {
  color: purple;
}
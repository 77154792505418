.O-Header {
	display: flex;
	border-bottom: 2px solid #C1C7CC; /* Bosch Gray 80*/
}

	.O-Header .header-contents {
		flex: 1;
		display: flex;
		align-items: center;
		gap: 3rem;
	}

	.O-Header .page-name {
		flex: 1;
		margin-top: 0;
		margin-bottom: 0;
		font-size: 1.75rem;
		font-weight: bold;
	}

	.O-Header .logo-container {
		display: flex;
		width: 108px;
		height: 1.5rem;
	}

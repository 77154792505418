#app-root {
	display: grid;
	overflow-y: auto;
}

.page-contents{
    display: flex;
    flex-direction: column;
    flex: 1;
}

